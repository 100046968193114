import { ICONS } from '@frontend/icons';

import logo from '../../../assets/images/distrispot_logo.png';
import ConnectionErrorAlert from './connection-error-alert/connection-error-alert.component';
import useNavbar from './navbar.controller';

const ID = 'navbar';
export interface NavbarProps {}

const Navbar = (props: NavbarProps) => {
    const viewProps = useNavbar(props);

    return (
        <div id={ID}>
            <ConnectionErrorAlert />
            <div className='d-flex flex-row justify-content-between align-items-center m-3 mb-4'>
                <img
                    className='img-fluid w-25'
                    alt='Logo'
                    src={logo}
                />
                <span></span>
                <div
                    className='btn-group'
                    role='group'>
                    {viewProps.showHome && (
                        <button
                            onClick={viewProps.onHome}
                            className='btn btn-light btn-rounded'>
                            <ICONS.BUTTON.HOME size={40} />
                        </button>
                    )}
                    {viewProps.showLogout && (
                        <button
                            onClick={viewProps.onLogout}
                            className='btn btn-light btn-rounded'>
                            <ICONS.BUTTON.LOGOUT size={40} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;

import { useHeartbeat } from '@frontend/edge/hooks';
import { TranslationProvider } from '@frontend/lang';

import Footer from './home-page/footer/footer.component';
import HomePage from './home-page/home-page.component';
import Navbar from './home-page/navbar/navbar.component';
import Initialisation from './initialisation/initialisation.component';

export function App() {
    useHeartbeat();
    return (
        <TranslationProvider>
            <div
                className='app-content'
                id='app-content'>
                <Navbar />
                <Initialisation>
                    <HomePage />
                </Initialisation>
                <Footer />
            </div>
        </TranslationProvider>
    );
}

export default App;

import { EntityType } from '@frontend/common';
import { ProductClient } from '@frontend/product/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useProduct:request': CustomEvent<string>;
    }
}

const REQUEST_PRODUCT_EVENT = 'useProduct:request';
const STORAGE_KEY = 'PRD_REQ';

export function productRequest(productId: string) {
    window.dispatchEvent(new CustomEvent(REQUEST_PRODUCT_EVENT, { detail: productId }));
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ProductResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_PRODUCT_EVENT, EntityType.PRODUCT, (ids) => ProductClient.resolveProducts(ids), props.dispatch);
    return <></>;
}

import { APIClient } from '@frontend/api-utils';
import { Token } from '@frontend/authentication-v2';

import { DriverProvision } from '@frontend/edge/types';

const endpoint = '/edge-api/v1';
export class DriverProvisionClient extends APIClient {
    public static async provision(provision: DriverProvision): Promise<Token> {
        const response = await this.post(`${endpoint}/provision`, provision, false);
        return await this.handleResponse<Token>(response);
    }

    public static async authorizeUser(provision: DriverProvision,accountId: string, userId: string): Promise<Token> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/users/${userId}/authorize`, provision);
        return await this.handleResponse<Token>(response);
    }
}

import { AuthenticationManager } from "@frontend/authentication-v2";
import { DeviceClient, HeartbeatClient } from "@frontend/edge/api";
import { DeviceType } from "@frontend/edge/types";
import { Logger } from "@frontend/Logger";
import { useEffect, useState } from "react";

export const useHeartbeat = () => {
    const [driverId, changeDriverId] = useState<string | null>(null);
    const [deviceId, changeDeviceId] = useState<string | null>(null);

    useEffect(() => {
        AuthenticationManager.getInstance().waitForToken().then((result) => changeDriverId(result.entity_id));
    }, []);

    useEffect(() => {
        if(driverId) {
            DeviceClient.fetchDevices({ driver_id: driverId, type: DeviceType.WEB_VIEW })
                .then((result) => {
                    if (result.results[0]) {
                        changeDeviceId(result.results[0].id);
                    } else Logger.warn(`No device found for driver ${driverId}, skipping heartbeat`);
                });
        }
    }, [driverId]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (driverId == null) return Logger.warn('Could not send heartbeat, driverId is null');
            if (deviceId == null) return Logger.warn('Could not send heartbeat, deviceId is null', {}, {'driver_id': driverId});
            return HeartbeatClient.postHeartbeat(driverId, deviceId)
                .then(() => Logger.debug('Heartbeat sent successfully.'))
                .catch(() => Logger.warn('Failed to send a haertbeat to the server.'));
        }, 60 * 1000);

        return () => clearInterval(interval)
    }, [driverId, deviceId]);
}
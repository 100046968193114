import { AuthenticationManager } from '@frontend/authentication-v2';
import { DriverType } from '@frontend/edge/types';
import { changeView, endUserSession } from '@frontend/user-interface-templates';
import { UserWorkflowClient } from '@frontend/user/api';
import { UserTriggerName } from '@frontend/user/types';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../redux/store';
import { NavbarProps } from './navbar.component';

interface ViewProps {
    showHome: boolean;
    showLogout: boolean;
    onHome: () => void;
    onLogout: () => void;
}

const useNavbar = (props: NavbarProps): ViewProps => {
    const dispatch = useAppDispatch();
    const navigationState = useAppSelector((state) => state.navigation);
    const [currentEntityId, changeCurrentEntityId] = useState<string | null>(null);

    useEffect(() => {
        AuthenticationManager.getInstance()
            .waitForToken()
            .then((token) => {
                changeCurrentEntityId(token.entity_id);
            });
    }, []);

    const onHome = () => {
        if (navigationState.user !== null) {
            UserWorkflowClient.triggerUserState(navigationState.user.accountId, navigationState.user.id, {
                trigger: UserTriggerName.USER_IDENTIFIED,
                source: { driver_type: DriverType.TERMINAL, driver_id: currentEntityId }
            });
        }
        dispatch(changeView(null));
    };

    const onLogout = () => {
        dispatch(endUserSession());
        dispatch(changeView(null));
    };

    return {
        showHome: navigationState.current !== null,
        showLogout: navigationState.user !== null,
        onHome,
        onLogout
    };
};

export default useNavbar;

import { Spinner } from '@frontend/elements';
import { IdleTimer } from '@frontend/idle-timer';
import { RenderUserInterface } from '@frontend/user-interface-templates';

import { useAppDispatch } from '../redux/store';
import useHomePage from './home-page.controller';

const ID = 'home-page';

const HomePage = () => {
    const dispatch = useAppDispatch();
    const viewProps = useHomePage();

    if (viewProps.isLoading || viewProps.view == null)
        return (
            <div id={ID}>
                <Spinner />
            </div>
        );

    return (
        <>
            <RenderUserInterface
                dispatch={dispatch}
                userInterfaceId={viewProps.view}
            />
            <IdleTimer
                enabled={viewProps.idleTimerEnabled}
                idleCallback={viewProps.idleTimerCallback}
            />
        </>
    );
};

export default HomePage;

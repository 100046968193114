import { Spinner } from '@frontend/elements';

import useProvision from './provision.controller';

export const ID = 'provision';

export enum ProvisionState {
    INIT,
    GATHERING_INFO,
    GATHERING_INFO_FAILED,
    AUTHENTICATING,
    PERMISSION_CHECK,
    PERMISSION_CHECK_FAILED,
    DONE
}

export interface Props {
    provisionDone?: () => void;
}

const Provision = (props: Props) => {
    const viewProps = useProvision(props);

    return (
        <div id={ID}>
            <div className='text-center m-4'>
                <h4>{viewProps.message}</h4>
                <Spinner show={viewProps.state !== ProvisionState.PERMISSION_CHECK_FAILED} />
            </div>
        </div>
    );
};

export default Provision;

import { Logger } from '@frontend/Logger';
import { DeviceServiceEventListener } from '@frontend/device/events';
import { DriverProvisionClient } from '@frontend/edge/api';
import { PackageServiceEventListener } from '@frontend/package/events';
import { ProductEventListener } from '@frontend/product/events';
import { TransactionServiceEventListener } from '@frontend/transaction/events';
import { changeView, endUserSession, startUserSession } from '@frontend/user-interface-templates';
import { UserEventListener } from '@frontend/user/events';
import { WorkflowStepTriggeredEvent } from '@frontend/workflow/types';
import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../redux/store';

interface ViewProps {
    isLoading: boolean;
    view: string | null;
    idleTimerEnabled: boolean;
    idleTimerCallback: () => void;
}

const useHomePage = (): ViewProps => {
    const dispatch = useAppDispatch();
    const [deviceEventListener] = useState<DeviceServiceEventListener>(DeviceServiceEventListener.getInstance(dispatch));
    const [transactionEventListener] = useState<TransactionServiceEventListener>(TransactionServiceEventListener.getInstance(dispatch));
    const [packageEventListener] = useState<PackageServiceEventListener>(PackageServiceEventListener.getInstance(dispatch));
    const [productEventListener] = useState<ProductEventListener>(ProductEventListener.getInstance(dispatch));
    const [userEventListener] = useState<UserEventListener>(UserEventListener.getInstance(dispatch));
    const navigationState = useAppSelector((state) => state.navigation);

    const callback = (event: MessageEvent<string>) => {
        Logger.log('Terminal received event: ', {}, event);
        const payload = JSON.parse(event.data) as { message: string; data: any };
        if (payload.message === 'show_user_interface') {
            const data = payload.data as WorkflowStepTriggeredEvent;
            if (data.user_id) {
                Logger.log('User id provided in last event: ', {}, data.user_id);
                if (navigationState.user?.id !== data.user_id && navigationState.driver) {
                    Logger.log('Authorizing user: ', {}, data.user_id, navigationState.driver, navigationState.cache);
                    DriverProvisionClient.authorizeUser(navigationState.driver, data.account_id, data.user_id)
                        .then((result) => {
                            dispatch(startUserSession({ id: data.user_id!, accountId: data.account_id, auth: result }));
                        })
                        .then(() => {
                            dispatch(changeView({ view: data.data.user_interface_id, cache: data }));
                        });
                    return;
                }
            }
            Logger.log('No authorization possible', {}, data, navigationState);
            dispatch(changeView({ view: data.data.user_interface_id, cache: data }));
        }
    };

    useEffect(() => {
        deviceEventListener.addCallback('HOMEPAGE_DEVICE_LISTENER', callback);
        transactionEventListener.addCallback('HOMEPAGE_TRANSACTION_LISTENER', callback);
        packageEventListener.addCallback('HOMEPAGE_PACKAGE_LISTENER', callback);
        productEventListener.addCallback('HOMEPAGE_PRODUCT_LISTENER', callback);
        userEventListener.addCallback('HOMEPAGE_USER_LISTENER', callback);

        return () => {
            deviceEventListener.removeCallback('HOMEPAGE_DEVICE_LISTENER');
            transactionEventListener.removeCallback('HOMEPAGE_TRANSACTION_LISTENER');
            packageEventListener.removeCallback('HOMEPAGE_PACKAGE_LISTENER');
            productEventListener.removeCallback('HOMEPAGE_PRODUCT_LISTENER');
            userEventListener.removeCallback('HOMEPAGE_USER_LISTENER');
        };
    }, []);

    const idleTimerCallback = () => {
        dispatch(endUserSession());
        dispatch(changeView(null));
    };

    return {
        isLoading: (navigationState.current || navigationState.root) == null,
        view: navigationState.current || navigationState.root,
        idleTimerEnabled: navigationState.user != null || navigationState.current != null,
        idleTimerCallback
    };
};

export default useHomePage;

import { Logger } from '@frontend/Logger';
import { DriverAuthenticationManager } from '@frontend/authentication-v2';
import { DriverClient } from '@frontend/edge/api';
import { DriverBrand, DriverProvision, DriverType } from '@frontend/edge/types';
import { initialiseDriver, initialiseNavigation } from '@frontend/user-interface-templates';
import { useEffect, useState } from 'react';

import { useAppDispatch } from '../../redux/store';
import { Props, ProvisionState } from './provision.component';

interface ViewProps {
    state: ProvisionState;
    message: string;
}

const useProvision = (props: Props): ViewProps => {
    const dispatch = useAppDispatch();
    const [authenticationManager] = useState<DriverAuthenticationManager>(DriverAuthenticationManager.getInstance('token'));
    const [state, changeState] = useState<ProvisionState>(ProvisionState.INIT);
    const [message, changeMessage] = useState<string>('Gathering info');
    const params = new URLSearchParams(window.location.search);
    const [provisionInfo, changeProvisionInfo] = useState<DriverProvision | null>(null);

    useEffect(() => {
        switch (state) {
            case ProvisionState.INIT:
                changeMessage('Gathering info');
                break;
            case ProvisionState.GATHERING_INFO_FAILED:
                changeMessage('Gathering info failed. Make sure the webview is configured correctly.');
                break;
            case ProvisionState.AUTHENTICATING:
                changeMessage('Authenticating');
                break;
            case ProvisionState.PERMISSION_CHECK:
                changeMessage('Provision check');
                break;
            case ProvisionState.PERMISSION_CHECK_FAILED:
                changeMessage(
                    'This device has not been provisioned correctly yet. Make sure it has been given the correct permissions and user interface has been linked.'
                );
                break;
            case ProvisionState.DONE:
                changeMessage('Provisioning done - Redirecting');
                break;
        }
    }, [state]);

    useEffect(() => {
        switch (state) {
            case ProvisionState.INIT:
                if (authenticationManager.loggedIn) {
                    changeProvisionInfo({
                        identifier: params.get('identifier')!,
                        type: params.get('type') ? (params.get('type') as DriverType) : DriverType.TERMINAL,
                        brand: DriverBrand.TELLOPORT
                    });
                    changeState(ProvisionState.PERMISSION_CHECK);
                } else changeState(ProvisionState.GATHERING_INFO);
                break;
            case ProvisionState.GATHERING_INFO:
                if (params.get('identifier') == null) changeState(ProvisionState.GATHERING_INFO_FAILED);
                else {
                    changeProvisionInfo({
                        identifier: params.get('identifier')!,
                        type: params.get('type') ? (params.get('type') as DriverType) : DriverType.TERMINAL,
                        brand: DriverBrand.TELLOPORT
                    });
                    changeState(ProvisionState.AUTHENTICATING);
                }
                break;
            case ProvisionState.AUTHENTICATING:
                authenticationManager.authenticate(provisionInfo!).then(() => changeState(ProvisionState.PERMISSION_CHECK));
                break;
            case ProvisionState.PERMISSION_CHECK:
                DriverClient.fetchDrivers({})
                    .then((drivers) => {
                        const driver = drivers.results.find((d) => d.identifier == provisionInfo?.identifier);
                        if (driver && driver.data && 'user_interface_id' in driver.data) {
                            changeState(ProvisionState.DONE);
                            dispatch(initialiseNavigation(driver.data.user_interface_id));
                        } else changeState(ProvisionState.PERMISSION_CHECK_FAILED);
                    })
                    .catch((err) => {
                        Logger.warn(err);
                        changeState(ProvisionState.PERMISSION_CHECK_FAILED);
                    });
                break;
            case ProvisionState.PERMISSION_CHECK_FAILED:
                setRetry(() => changeState(ProvisionState.PERMISSION_CHECK));
                break;
            case ProvisionState.DONE:
                props.provisionDone && props.provisionDone();
                break;
        }
    }, [state]);

    useEffect(() => {
        dispatch(initialiseDriver(provisionInfo));
    }, [provisionInfo]);

    return { state, message };
};

export default useProvision;

function setRetry(callback: () => void) {
    const retry: NodeJS.Timeout = setTimeout(() => {
        callback();
        return clearTimeout(retry);
    }, 60000);
}

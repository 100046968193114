import { accountStore } from '@frontend/account/redux';
import { contactStore } from '@frontend/contact/redux';
import { moduleStore } from '@frontend/module/redux';
import { packageStore } from '@frontend/package/redux';
import { productCategoryStore } from '@frontend/product-category/redux';
import { productStore } from '@frontend/product/redux';
import { settingStore } from '@frontend/setting/redux';
import { slotStore } from '@frontend/slot/redux';
import { spotStore } from '@frontend/spot/redux';
import { userInterfaceButtonStore } from '@frontend/user-interface-button/redux';
import { navigationStore } from '@frontend/user-interface-templates';
import { userInterfaceStore } from '@frontend/user-interface/redux';
import { workflowStepStore } from '@frontend/workflow-step/redux';
import { workflowSharedStore, workflowStore } from '@frontend/workflow/redux';
import { configureStore } from '@reduxjs/toolkit';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { scannerStore } from './scanner-slice';

export const store = configureStore({
    reducer: {
        ...accountStore,
        ...workflowStore,
        ...workflowStepStore,
        ...userInterfaceStore,
        ...userInterfaceButtonStore,
        ...workflowSharedStore,
        ...productCategoryStore,
        ...slotStore,
        ...moduleStore,
        ...spotStore,
        ...productStore,
        ...contactStore,
        ...packageStore,
        ...settingStore,

        //terminal states
        ...navigationStore,
        ...scannerStore
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
